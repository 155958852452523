import { render, staticRenderFns } from "./notebook-nft.vue?vue&type=template&id=50f7fe6a&scoped=true&"
import script from "./notebook-nft.vue?vue&type=script&lang=ts&"
export * from "./notebook-nft.vue?vue&type=script&lang=ts&"
import style0 from "./notebook-nft.vue?vue&type=style&index=0&id=50f7fe6a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50f7fe6a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VDivider,VProgressLinear,VSheet,VSpacer})
